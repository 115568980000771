import React ,{Component} from 'react'
import DesktopNav from './desktop-nav';
import MobileNav from './mobile-nav';
import Backdrop from "./backdrop";
class NavBar extends Component
{

    state = {
        userIsScrolled: false,
        mobileNavbarOpen: false,
        screenWidth:window.screen.availWidth
      };
    
      componentDidMount() {
       
        window.addEventListener("scroll", this.userIsScrolled);
      }
      componentWillUnmount() {
        window.removeEventListener("scroll", this.userIsScrolled);
      }
    
      // Detect if user is scorlled down (used for add/disable extra large navbar)
      userIsScrolled = () => {
      
        if (window.pageYOffset > 80) {
          this.setState({ userIsScrolled: true });
        } else {
          this.setState({ userIsScrolled: false });
        }
      };
      // On closeMobileMenu click close navbar
      closeMobileMenu = () => {
        this.setState({ mobileNavbarOpen: false });
      };
      // Mobile menu handler
    
      mobileMenuOpen = () => {
        this.setState({ mobileNavbarOpen: true });
      };


render(){

    let backdrop = <Backdrop closeMobileMenu={this.closeMobileMenu} />;
    if (this.state.mobileNavbarOpen) {
      backdrop = (
        <Backdrop closeMobileMenu={this.closeMobileMenu} isOpen={true} />
      );
    }
    // MOBILE NAVBAR RENDER
    let mobileNavbar = <MobileNav />;
    if (this.state.mobileNavbarOpen) {
      mobileNavbar = (
        <MobileNav isOpen={true} closeMobileMenu={this.closeMobileMenu} />
      );
    }



return(
    <React.Fragment>
        {mobileNavbar}
    {backdrop}
    <DesktopNav
      userIsScrolled={this.state.userIsScrolled}
      mobileMenuOpen={this.mobileMenuOpen}
      screenWidth={this.state.screenWidth}
    />
    </React.Fragment>
    
)}
}
export default NavBar;