import React from "react";
import { Link } from "react-router-dom";
// SCSS
import "./navbar.scss";
// Assets
import CloseIcons from '../../assets/navbar/mobile-close.svg';
import Logo from "../../assets/navbar/andaman-tourisms-logo.webp";

const mobileNav = (props) => (
  <div className={`mobile__navbar ${props.isOpen ? "mobile__open" : ""}`}>
    <div className="mobile__navbar-close" onClick={props.closeMobileMenu}>
      <img src={CloseIcons} alt="close" />
    </div>
    <div className="mobile__navbar-logo flex-center">
      <Link
        to="/"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        onClick={props.closeMobileMenu}
      >
        {/* <h1 style={{color:'#fff'}}>Andaman Tourisms</h1>    */}
        <img src={Logo} alt="andaman tourisms logo" className="pointer logo" style={{ width: "30rem" }} />
      </Link>
    </div>
    <div className="nav">
      <ul>

        <li>
          {/* <Link activeClass="active-link" to="/about" hash="#prop" spy={true} smooth={true} offset={-30} duration={500}>
              Avout
            </Link> */}
          <Link to={{
            pathname: "/",
            hash: "#about-us",
            search: ""
          }}
            onClick={props.closeMobileMenu}
          >
            About Us
            </Link>

        </li>
        <li>
          <Link activeClass="active-link" to="/Packages" onClick={props.closeMobileMenu}>
            Itinerary
            </Link>
        </li>
        <li>
          <Link activeClass="active-link" to="/Contact-us" onClick={props.closeMobileMenu}>
            Contact Us
            </Link>
        </li>

      </ul>
    </div>
  </div>
);

export default mobileNav;
