import React from 'react';
import { Link } from "react-router-dom";
// SCSS
import './navbar.scss';
// Assets
import LogoImg from '../../assets/navbar/andaman-tourisms-logo.webp';
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';
import Mail from '../../assets/topbar/mail.svg';
import Phone from '../../assets/topbar/phone-call.svg';
import Place from '../../assets/topbar/place.svg';

const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? props.screenWidth < 500 ? "" : "extraLargeNavbar" : ""}`}>
    <div className="topcontact" >
      <div className="wrapper flex-s-between">
        {/* &nbsp; <img src={Phone} alt="email" width="20px"/><img src={Mail} alt="email" width="20px"/> 
       */}
        <div id="div_top_hypers">
          <ul id="ul_top_hypers">
            <li> <img src={Phone} alt="email" width="20px" /> +91 9434260164  &nbsp;/  03192-233577 </li>
            <li><a href="mailto:andamantourisms@gmail.com" style={{ color: "rgb(0,0,0)" }}><img src={Mail} alt="email" width="20px" /> andamantourisms@gmail.com</a> </li>
            <li><a href="https://g.page/Pooja-Tours-and-Travels-AN" target="_blank" rel="noopener noreferrer" style={{ color: "rgb(0,0,0)" }}><img src={Place} alt="place" width="20px" /> C/O Pooja Tours and Travels, 7 Panipat Road, Hadoo, Port Blair </a></li>
          </ul>
        </div>

      </div>
    </div>
    <div className="wrapper flex-s-between">
      <div>



        <Link to="/" spy={true} smooth={true} offset={0} duration={500}>
          <img src={LogoImg} alt="andaman tourisms logo" className="pointer logo" />
          {/* <h1 >Andaman Tourism</h1>    */}
        </Link>
      </div>
      <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      <div className="desktop__menu">
        <ul className="flex-s-between">
          <li>
            {/* <Link activeClass="active-link" to="/about" hash="#prop" spy={true} smooth={true} offset={-30} duration={500}>
              Avout
            </Link> */}
            <Link to={{
              pathname: "/",
              hash: "#about-us",
              search: ""
            }} >
              About Us
            </Link>

          </li>
          <li>
            <Link activeClass="active-link" to="/Packages">
              Itinerary
            </Link>
          </li>
          <li>
            <Link activeClass="active-link" to="/Contact-us">
              Contact Us
            </Link>
          </li>

        </ul>
      </div>
    </div>
  </nav>
);

export default desktopNav;